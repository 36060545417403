.item {
  flex-grow: 0;
  flex-shrink: 0;
  font: var(--field-value-font, 14px Sans-Serif);
  height: var(--list-item-height, 24px);
  padding: 0 8px;
  min-width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cursor-item {
  composes: item;
  background-color: #eee;
}

.toggle {
  padding-right: 12px;
}
