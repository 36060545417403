.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid #ccc;
  font-size: 24px;
  height: 48px;
}

.icon {
  width: 32px;
}

.title {
  margin-left: 16px;
  flex-grow: 1;
}
