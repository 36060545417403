.root {
  margin: 0px 16px;
  font-size: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  font-size: 24px;
  color: #999;
  animation-duration: 400ms;
}

.text {
  margin-left: 12px;
  font-size: 16px;
  color: #999;
}
