.list-container {
  outline: none;
}

.list {
  font: var(--field-value-font, 14px Sans-Serif);
  cursor: pointer;
}

.disabled-list {
  composes: list;
  opacity: var(--disabled-opacity, .4);
  cursor: default;
}

.popper {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: -16px;
  margin-left: -8px;
  padding: 0;
  background-color: white; 
  border: 1px solid #ddd;
  box-shadow: 4px 4px 8px 4px #ccc;
  overflow-y: scroll;
}
