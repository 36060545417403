.grid {
  display: grid;
  grid-template-columns: 250px 150px 100px 1fr 80px;
  padding: 6px 16px;
  column-gap: 16px;
}

.column-headers {
  composes: grid;
  font-family: Futura, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  color: steelblue;
  background: #ccd8;
}

.column {
  cursor: pointer;
}

.column-right {
  composes: column;
  text-align: right;
}

.sort-icon {
  margin-left: 4px;
}

.right-align {
  text-align: right;
}

.record {
  composes: grid;
  border-top: 1px solid #ccc;
  cursor: pointer;
}

.record:nth-child(even) {
  background: #eee;
}

.key {
  font-family: Menlo, monospace;
  font-size: 13px;
  color: midnightblue;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.action {
}

.priority {
}

.disabled {
  color: salmon;
}

.comment {
  flex-grow: 1;
  margin-right: 16px;
  color: #666;
  font-size: 12px;
}

.ts {
  color: #777;
  text-align: right;
}

.add-icon {
  color: olive;
}

@media (hover: hover) {
  .alias:hover {
    background: #ff03;
  }
}
