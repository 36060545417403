.alert {
  padding: 20px;
}

.switcher {
  font-size: 24px;
  display: flex;
  flex-direction: row;
}

.switcher-icon {
  align-self: center;
  font-size: 16px;
  cursor: pointer;
}

.title {
  margin-right: 10px;
}

.action {
  color: steelblue;
}
